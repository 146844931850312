.badge{
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    &.status-generating {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-cancelled {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-error {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-offer {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-payout {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-order {
        background: #C8E6C9;
        color: #256029;
    }
}